jQuery(document).ready(function($) {
	
	
    /* !----------------- SCROLL MAGIC ------------------! */
	
	if( $(window).width() > 767 ){	
		
		var controller = new ScrollMagic.Controller();		
		
		/* 
		 *
		 * -------------------------- Slide in images
		 *
		 */
		$('.mx-intro-sec').each(function(){	
			var scene = new ScrollMagic.Scene({
			triggerElement: this,
			triggerHook:0,
			reverse:false
			})
			.setClassToggle(".mx-intro-sec .ptrn", "slideInRight")
			//.addIndicators({name: 'sright intro'}) // add indicators (requires plugin)
			.addTo(controller);
		});	
		$('.mx-list-sec').each(function(){	
			var scene2 = new ScrollMagic.Scene({
			triggerElement: this,
			//offset:-$('.main-header').outerHeight(true),
			triggerHook:0.25,
			reverse:false
			})
			.setClassToggle(".mx-list-sec .ptrn", "slideInLeft")
			//.addIndicators({name: 'sleft'})
			.addTo(controller);		
		});		
		$('.mx-img-grid-sec').each(function(){	
			var scene3 = new ScrollMagic.Scene({
			triggerElement: this,
			//offset:-$('.main-header').outerHeight(true),
			triggerHook:0.25,
			reverse:false
			})
			.setClassToggle(".mx-img-grid-sec .ptrn", "slideInRight")
			//.addIndicators({name: 'sright img-grid'})
			.addTo(controller);			
		});		

		$('.mx-1-col-sec').each(function(){	
			var scene4 = new ScrollMagic.Scene({
				triggerElement: this,
				//offset:- $('.main-header').outerHeight(true),
				triggerHook:0.5,
				reverse:false
			})
			
			.setClassToggle('.mx-1-col-sec .ptrn', "slideIn")
			//.addIndicators({name: 'one column'})
			.addTo(controller);
			
		});	

		$('.relatedprod-sec').each(function(){	
			var scene5 = new ScrollMagic.Scene({
			triggerElement: this,
			//offset:-$('.main-header').outerHeight(true),
			triggerHook:0.25,
			reverse:false
			})
			.setClassToggle(".relatedprod-sec .right-ptrn.ptrn", "slideInRight")
			//.addIndicators({name: 'sright related'})
			.addTo(controller);			
		});		
		
		$('.mx-2-col-sec').each(function(){	
			var scene6 = new ScrollMagic.Scene({
			triggerElement: this,
			//offset:-$('.main-header').outerHeight(true),
			triggerHook:0.25,
			reverse:false
			})
			.setClassToggle(".mx-2-col-sec .right-ptrn.ptrn", "slideInRight")
			//.addIndicators({name: 'sright related'})
			.addTo(controller);			
		});	
		
		$('.mx-video-sec').each(function(){	
			var scene7 = new ScrollMagic.Scene({
			triggerElement: this,
			//offset:-$('.main-header').outerHeight(true),
			triggerHook:0.25,
			reverse:false
			})
			.setClassToggle(".mx-video-sec .ptrn", "slideInLeft")
			//.addIndicators({name: 'sleft'})
			.addTo(controller);		
		});		
		
    }
    
    
    		/* !----------------- Intro Swiper ------------------! */
	var swiper = new Swiper('.mx-slider-sec.has-swiper .swiper-container', {
		loop: true,
		slidesPerView: 1, 
		autoplay: {
        	delay: 3000,
			disableOnInteraction: false,
      	}, 
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		},
		on: {
			init: function () {
				console.log('swiper initialized');
				var $color = $('.swiper-slide-active').attr("data-bg");
				$('.mx-featured-slider').css('background-color', $color);
				$('.mx-featured-slider').css('color', $color);
			},
		}
	});
	if( $(swiper).length )  {
		//alert('ya');
		swiper.on('slideChange', function () {
			var activeSlide = swiper.slides[swiper.activeIndex];
			var $color = $(activeSlide).attr("data-bg");
			$('.mx-featured-slider').css('background-color', $color);
			$('.mx-featured-slider').css('color', $color);
			//console.log($color);
		});
	}
	
	var relatedswiper = new Swiper('.relatedprod-sec.has-swiper .swiper-container', {
		slidesPerView: 3,   
		breakpoints: {
	    // when window width is <= 767px
		    767: {
		      slidesPerView: 1
		    }
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		},
		autoplay: {
        	delay: 3000,
			disableOnInteraction: false,
      	}
	});
       
    ///single cider dropdown for tabs instead 

	$('.single-brew .menu-stacked ul.dropdown-menu li a').on('click', function(e) {
		e.preventDefault();
		var href = $(this).attr('href');
		var txt = $(this).html();
		var currenttext = $('.menu-stacked .btn .current').html();

		//replace dropdown button text		 
		$(".menu-stacked .btn .current").html(function () {
			return $(this).html().replace(currenttext, txt); 
		});
		
		$('.dropdown-menu a[href="' + href + '"]').tab('show');

        $('.menu-stacked ul.dropdown-menu li').removeClass('active');

        var $parent = $(this).parent();
        if (!$parent.hasClass('active')) {
            $parent.addClass('active');
        }
		
	});

	
	//----------- Cart Toggle
	$('.utility-nav li.dropdown .dropdown-toggle').on('click', function(event){
		event.stopPropagation();
		$('.main-header').removeClass('open');
		$('.main-header').toggleClass('cart-open');
		$(this).parent().toggleClass('active');
		//alert('work');
	});
	
	$('.close-cart').on('click', function(event){
		$('.main-header').toggleClass('cart-open');
		$('.utility-nav li.dropdown .dropdown-toggle').parent().toggleClass('active');
		//alert('work');
	});

	/*
$('.adding-to-cart- button').on('click', function(){
		$('.cart-link').addClass('filling');
		alert('work');
	});
*/
	
   
   //gray out unavailable products on product listing page
    $("#cf_onclick").click(function() {
		$(".product-loop article img.top").toggleClass("transparent");
		$(".product-loop article > div").toggleClass("unavail");
	});
   	   


});

  
  function auto_tab(){
		// Javascript to enable link to tab
		var url = document.location.toString();
		if (url.match('#')) {
		    $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
		} 
		
  }
  auto_tab();



// add scroll magic to the beer finder page
// for the filter sub nav
$(function () { 
	if( $("beer-finder") .length ) {
		var controller = new ScrollMagic.Controller();
		// build scene
		var scene = new ScrollMagic.Scene({triggerElement: "#beer-finder-trigger", duration : $(window).height})
			.setPin(".beer-finder-filter")
			.setClassToggle( '.beer-finder-filter', 'pinned')
// 			.addIndicators({name: "2 (duration: 0)"}) // add indicators (requires plugin)
			.addTo(controller);
	}
});


	function hex2rgb(hexStr){
	    // note: hexStr should be #rrggbb
	    var hex = parseInt(hexStr.substring(1), 16);
	    var r = (hex & 0xff0000) >> 16;
	    var g = (hex & 0x00ff00) >> 8;
	    var b = hex & 0x0000ff;
	    return [r, g, b];
	}

    /* Function to convert rgb-to-hsl. */
	function rgb2hsl(rgbArr){
    var r1 = rgbArr[0] / 255;
    var g1 = rgbArr[1] / 255;
    var b1 = rgbArr[2] / 255;
 
    var maxColor = Math.max(r1,g1,b1);
    var minColor = Math.min(r1,g1,b1);
    //Calculate L:
    var L = (maxColor + minColor) / 2 ;
    var S = 0;
    var H = 0;
    if(maxColor != minColor){
        //Calculate S:
        if(L < 0.5){
            S = (maxColor - minColor) / (maxColor + minColor);
        }else{
            S = (maxColor - minColor) / (2.0 - maxColor - minColor);
        }
        //Calculate H:
        if(r1 == maxColor){
            H = (g1-b1) / (maxColor - minColor);
        }else if(g1 == maxColor){
            H = 2.0 + (b1 - r1) / (maxColor - minColor);
        }else{
            H = 4.0 + (r1 - g1) / (maxColor - minColor);
        }
    }
 
    L = L * 100;
    S = S * 100;
    H = H * 60;
    if(H<0){
        H += 360;
    }
    var result = {h:H, s:S, l:L};
    return result;
}

// page content if 'true' this div is overflowing dont center content  
if ($("section.reg-content .content-area > .row").prop('scrollHeight') > $("section.reg-content .content-area > .row").prop('clientHeight')){
    
     $("section.reg-content .container-fluid > div").removeClass('flex-row-center');
     $("section.reg-content .container-fluid > div").addClass('flex-row');
     $(".content-area .content-text").addClass('uncenter');
     //alert('overflowing');
}
