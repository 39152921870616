/*
var ss = document.createElement("link");
ss.type = "text/css";
ss.rel = "stylesheet";
ss.href = "/wp-content/themes/mx-theme/assets/css/screen.css";
document.getElementsByTagName("head")[0].appendChild(ss);
*/

/**
*
* This class is loaded by default, and called in the footer
* If there is a page object available that will also be called from the footer after global. 
* 
* 
**/
var global = (function(global_options, default_global_options){

	/** Constructor globals **/	
	var options	 


	init();

	

	/**
	 * init function.
	 * 
	 * @access public
	 * @return void
	 */
	function init(){
		$('body').css('opacity', 1)
		//console.log('no global ');
		options = $.extend({}, global_options, default_global_options);
		encode_emails();
		//Turn on some functionality 
		clickable();
		data_isotope();
		data_owl();
		data_chosen();
		mailchimp_simple_modal()
// 		mailchimp_api_modal();
//		form_submitter();
		campaign_monitor();
		smooth_scroll(  )
				
/*
		$('[data-validate]').each(function(){
			$(this).validate();
		});
*/

		$('.desktop [data-toggle="tooltip"]').tooltip();
		
		$('.desktop [data-toggle="popover"]').popover();
		
		$('[data-loader]').each(function(){
			$(this).html(loader_content($(this).data('loader')))			
		});


//		stop_modal_video('.modal');
		
		
		$('[data-autoload="true"]').modal('show');


		$('[data-show-hide]').each(function(e){
			var target = $($(this).data('showHide'));
			$(this).on('click', function(a){
				$(target).toggleClass('active');
				$(this).toggleClass('active');
			})
		})

		$('[data-btn-show-hide]').each(function(e){
			var show_target = $($(this).data('show'));
			var hide_target = $($(this).data('hide'));
			$(this).on('click', function(a){
				$(show_target).show();
				$(hide_target).hide();
			})
		})
		
		


	}//init

	
	// old browsers
	var ie9 = $('body').attr('class').indexOf('msie-9'),
		ie8 = $('body').attr('class').indexOf('msie-8'),
		ie7 = $('body').attr('class').indexOf('msie-7');
	
	
	if( ie8 > 0 || ie9 > 0 ) {
		var message = 'It looks like your browser is out of date!',
			options = {
				'type': 'danger',
				'link_url': 'http://browsehappy.com/',
				'link_text': 'Upgrade my browser',
				'body_text': 'We strongly recommend upgrading to a modern web browser in order to get the best possible experience using our website.'
			};
		
		
		mx_alert( message, options );
	}
			
	
	function encode_emails(){
		// encode emails 		
		$('[data-encoded-email]').each(function(){
			var link = decodeBase64($(this).data('encoded-email'));
			$(this).attr('href', 'mailto:' + link).text(link);
		})
		
	}
	

	/**
	 * Javascript window.open 
	 * 
	 ** Used for items that should link , but aren?t anchors
	 ** Looks at data for href and target. 
	 * 
	 * 
	 * @access public
	 * @return void
	 */
	function clickable(){
		var option_pressed = false;
		$(document).on({
	   keydown: function(e) {
        if(e.which == 91) option_pressed = true;
	    },
	    keyup: function(e) {
	    	option_pressed = false;
	    }
	  });
		// add click functionality to non anchors
		$('[data-click-href]').each(function(){
			$(this).css('cursor', 'pointer');
			$(this).on({
				mouseup : function( e ) {
					e.preventDefault();
					var $target = $(this).data('click-target') || '_self';
					if( option_pressed ) $target = "_blank";
					window.open($(this).data('click-href'),  $target);
				}
			})
		})
	}
	
	function jsfill(){
		$('input[name="jsfilled"]').val('filled')
		$('.jsfill').val('filled');
	}

	jsfill();
	// @Dependencies : jquery-form jquery-validate
	function form_submitter(){
		$('.jsfill').val('filled');
		
		// generic ajax form submission 
		// works with contact form 
		$('.btn-submit').on('click', function(e){
			e.preventDefault();
			
			var button = $(this);
			var form = button.closest('form');
			var hfields = form.find('.hiddenfields');
			var loader = form.find('.loader');
			// if the form isn't valid then stop here. 
			if(form.valid()){
	
				
				//button.text( button.data( 'sending-text' ) );
				//button.default_text = button.text();
		
				button.prop('disabled', 'diasbled');
				form.addClass('loading');
		
				$('<input/>', {
					'name'	: 'jsfilled',
					'value' : 'filled',
					'class' : 'off-screen'
				})
				.appendTo(hfields);
					
				form.ajaxSubmit({
					dataType : 'json',
					beforeSubmit : function(a, b, c){ },
					error: function() {
// 						console.log(response);
					},
					success : function(response){
// 						console.log(response.success);
						if(response.success){
							button.text(button.data('sent-text'))
							form.prop('disabled', 'disabled').hide();
							form.parent().html(response.response);
						}else{
							button.removeProp('disabled');
							form.after(response.response);
							button.text(button.default_text)
							console.log('err', response);
						}
					}
		
				})
			}
			return false;
		})
		
	}	
	

	function data_isotope(){
		$('[data-isotope]').each(function(e){
			
			var _that = $( this );
			var options = _that.data( 'isotope' );
			var _options = {
				itemSelector: '.item',
				masonry: {
					columnWidth: '.item',
					
				}
			}
			options = $.extend(_options, options);
			_that.imagesLoaded( function(){
				_that.isotope(options);
			});
		})
	}
	
	
	function data_chosen(){
		$('[data-chosen]').each(function(e){
			var _that = $( this );
			var options = _that.data( 'isotope' );
			var _options = { }
			options = $.extend(_options, options);
			_that.chosen(options);
		})
	}
	


	function data_owl(options){
		$('[data-owl]').each(function(e){
			var _that = $( this );
			var options = _that.data( 'owl' );
			var _options = {
				loop : true,
				nav : true,
/*
				dots : false,
				slideBy : 'page',
*/
// 				navText : ['<i class = "fa fa-chevron-left"></i>','<i class = "fa fa-chevron-right"></i>']
			}
			options = $.extend(_options, options);
			_that.owlCarousel( options );
/*
			var btn_next = $(_that.prev('.button-next'));
			var btn_prev = $(_that.prev('.button-prev'));
			
			btn_next.click(function() {
			    _that.trigger('next.owl.carousel');
			})
			btn_prev.click(function() {
			    _that.trigger('prev.owl.carousel', [300]);
			})
*/
			
		})
	}
	

	
	function read_more_toggle() {
		
		$(document).on('click', '.read-more > a', function(e) {
			e.preventDefault(); 
			$(this).parents('.more-less').toggleClass('more');
			
			if( $(this).text().indexOf('More') > 0 ) {
				$(this).text('Read Less -');
			}else {
				$(this).text('Read More +');
			}
			
		});
	}


/** ########################## 	**/
/** 	MAILCHIMP SIMPLE + API  	**/
/** ##########################	**/
	/**
	 * mailchimp_simple_modal function.
	 * 
	 * Submits to mailchimp directly
	 *
	 * @access public
	 * @param mixed $form
	 * @return void
	 */
	function mailchimp_simple_modal($form){
		$form = typeof($form) !== 'undefined' ? $($form) : $('#form-mailchimp-footer');
		
		$form.on('submit', function(e){
			e.preventDefault();
			$form.addClass('loading');
			var $data = $form.serialize();
			$('input, button', this).prop('disabled', true);
		  $.ajax({
		      type: "GET",
		      url: $form.attr("action"),
		      data: $data,
		      cache: false,
		      dataType: "jsonp",
		      jsonp: "c", // trigger MailChimp to return a JSONP response
		      contentType: "application/json; charset=utf-8",
		      error: function(error){
						$(this).removeClass('loading');
						$('input, button', this).removeProp('disabled');
		      },
		      success: function(data){
			      mailchimp_success(data, $form)
			   }
			})
			return false;
		})
	}

	/**
	 * mailchimp_api_modal function.
	 * 
	 * Submits to api in MX_forms 
	 *
	 * @access public
	 * @param mixed $form
	 * @return void
	 */
	function mailchimp_api_modal($form){
		$form = typeof($form) !== 'undefined' ? $($form) : $('#form-mailchimp-footer');
		$form.on('submit', function(e){
			e.preventDefault();
			$form.addClass('loading');
			var $data = $form.serialize();
			$('input, button', this).prop('disabled', true);
		  $.ajax({
		      type: "POST",
		      url: $form.attr("action") + '?submit_mailchimp_form=true',
		      data: $data,
		      cache: false,
		      dataType: "json",
		      error: function(error){
						$(this).removeClass('loading');
						$('input, button', this).removeProp('disabled');
		      },
		      success: function(data){
			      mailchimp_success(data, $form)
			     }
			})
			return false;
		})
	}


	/**
	 * mailchimp_success function.
	 * 
	 * @access public
	 * @param mixed data
	 * @param mixed $form
	 * @return void
	 */
	function mailchimp_success(data, $form){
  	if(data.result === 'success'){
    	$('#mc-footer-success .modal-title').text('Thank You!');
    	$('input', $form).val('');
			$form.removeClass('loading');
			$('#mc-footer-success .modal-message').html('<p>' + data.msg + '</p>');
    	$('#mc-footer-success').modal('show');
  	}else if(data.result === 'error'){
    	$('#mc-footer-success .modal-title').text('Subscription Error');
			$form.removeClass('loading');
			$('input, button', $form).removeProp('disabled');
			$('#mc-footer-success .modal-message').html('<p>' + data.msg + '</p>');
    	$('#mc-footer-success').modal('show');
    }
		$form.removeClass('loading').removeProp('disabled');
	}


/** ########################## 	**/
/** 	CAMPAIGN MONITOR			  	**/
/** ##########################	**/


	/**
	 * campaign_monitor function.
	 * 
	 * @access public
	 * @return void
	 */
	function campaign_monitor(){
		$('#cm-form button[type="submit"]').on('click', function (e) {
			var $form = $($(this).closest('form'));
			
			
	    e.preventDefault();
			var url = $form.attr('action');
			$.ajax({
		    url: url,
		    jsonp: "callback",
		    dataType: "jsonp",
		    data: $form.serialize(),
		    success: function( response ) {
			    if( response.Status == 200){
				    $('#cm-form form').hide();
				    $('#cm-form .cm-message p').text( response.Message );
				    $('#cm-form .cm-message').removeClass('hide').show();
			    }
			    if( response.Status == 400 ){
				    alert( response.Message );
			    }
		    }
			});
		});
	}

	

	//--------------------- SMOOTH SCROLL
	function smooth_scroll( targetEl , offset, offsetEl ){
		var f_options = {};
		var default_f_options = { 
			targetEl : 'a[href^="#"], .scroller a[href]',
			offset : 0 ,
			offsetEl : false 
		};
		if( typeof( options ) !== 'undefined' && typeof( options.smooth_scroll ) !== 'undefined' ){
			f_options =$.extend(  default_f_options  , options.smooth_scroll);
		}else{
			f_options = default_f_options;
		}
		
    if( f_options.offsetEl ){
      var header = $( f_options.offsetEl );
			if( header.length > 0 ) f_options.offset = header.height()
    }
    $( f_options.targetEl ).click(function(e) {
	    if ( !$(this).attr('data-toggle') ){ 
    		e.preventDefault();
		    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
		      var target = $(this.hash);
		      target = target.length ? target : $('[name="' + this.hash.slice(1) +'"]');
		      if (target.length) {
				    $('html,body').animate({    
						scrollTop: target.offset().top - f_options.offset + 'px'
		        	}, 1000);
		        return false;
		      } 
		    }
	    }
		});
		
		
	}
		


		




	//return 
	return {
		init : function(){
			init();
		}
	}
	
});





/**
 * handle_youtube_video function.
 * 
 * @access public
 * @param mixed btn_play_id
 * @return void
 */
function handle_youtube_video(btn_play_id, callbacks){
	
	var player;
	var playing = false;
	var btn_play = $(btn_play_id);
	var video_player_id = btn_play.data('player');
	var container = $(btn_play.data('container'));
	// define some callbacks	
	var _callbacks = {
		after_pause : function(){ },
		after_play : function(){ }
	}
	// merge the supplied callbacks for overriding
	callbacks = $.extend(_callbacks, callbacks)
	
	function load_script(){
	  //load  the IFrame Player API code asynchronously.
	  var tag = document.createElement('script');
	  tag.src = "https://www.youtube.com/iframe_api";
	  var firstScriptTag = document.getElementsByTagName('script')[0];
	  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	}

	// initialize the player when ready and fire some callbacks
	window.onYouTubePlayerAPIReady = function() {
	  // create the global player from the specific iframe (#video)
	  player = new YT.Player( video_player_id , {
	    events: {
	      // call this function when player is ready to use
	      'onReady': onPlayerReady,
	      'onStateChange' : onStateChange
	    }
	  });
	}
	// custom state change handler
	function onStateChange( event ){
		if(event.data === 2){
			video_paused();
		}
		if(event.data === 1){
			video_playing();
		}
		if(event.data === 0){
			video_finished();
		}
	}
	// when the video is paused
	function video_paused(){
		playing = false;
		$(btn_play).removeClass('paused').addClass('play');
		$( container ).removeClass('play').addClass('paused');
		callbacks.after_pause(player, playing, btn_play, video_player_id, container);
	}

	// when the video is playing
	function video_playing(){
		playing = true;
		$(btn_play).removeClass('play').addClass('paused');
		$( container ).removeClass('paused').addClass('play');
		callbacks.after_play(player, playing, btn_play, video_player_id, container);
	}


	// when the video is paused
	function video_finished(){
		playing = false;
		$(btn_play).removeClass('paused').addClass('play');
		$( container ).removeClass('play').addClass('paused');
		callbacks.after_pause(player, playing, btn_play, video_player_id, container);
	}

	// when the player is ready 
	function onPlayerReady(event) {
		$( btn_play).removeClass('loading');
		$( container ).removeClass('loading');
		video_paused();
	  btn_play.on("click tap touchstart", function() {
		  console.log('this is happening');
			if( !playing ){
				playing = true;
		    player.playVideo();
		    video_playing();
			}else{
				playing = false;
				player.pauseVideo();
		    video_paused();
			}
	  });
	}


	load_script();


}
	//hide custom video play button for ios device
	if ($('body').hasClass('mobile') || $('body').hasClass('tablet')) {
		$('section.youtube-player').removeClass('has-cover-image');
		$('button#play-button').hide();
		$('section#youtube-player').addClass('video-fix');
	}

	function video_click(container){
	    $(container).each(function(){
	    	var video = $(this).find('video').get(0);
		    var playbtn = $(this).find('.play-pause');
	        playbtn.on('click tap touchstart', function(){
				if(video.paused == true){
					//$(this).html('<span class="icon-pause"></span>').addClass('paused');
					$(this).addClass('paused');
					video.play();
					video.setAttribute("controls","controls") 
	    		}else{
					//$(this).html('<span class="icon-play4"></span>').removeClass('paused');
					video.pause();
					video.removeAttribute("controls")   
	        	}
	    	});
	    });
	}    
	video_click('.video-box.html5');





// give console to window if it doesn't exist
if (typeof console === "undefined") console = { log: function() { } };




/**
 * in_array function.
 * 'a[href*=#]:not([href=#])'
 * @access public
 * @param mixed needle
 * @param mixed haystack
 * @param mixed argStrict
 * @return void
 */
function in_array(needle, haystack, argStrict) {

  var key = '',
    strict = !! argStrict;

  //we prevent the double check (strict && arr[key] === ndl) || (!strict && arr[key] == ndl)
  //in just one for, in order to improve the performance 
  //deciding wich type of comparation will do before walk array
  if (strict) {
    for (key in haystack) {
      if (haystack[key] === needle) {
        return true;
      }
    }
  } else {
    for (key in haystack) {
      if (haystack[key] == needle) {
        return true;
      }
    }
  }

  return false;
}


function stop_modal_video($modal){
	if($($modal).length > 0){
		$($modal).each(function(){
			var src = $(this).find('iframe').attr('src');
			if(src != 'undefined'){
				$(this).on('hidden.bs.modal', function () {
		            $(this).find('iframe').attr('src', '');
		            $(this).find('iframe').attr('src', src);
				})
			}
		});
	}
}



/**
 * decode base64 encoded emails
 * 
 * @access public
 * @param mixed s
 * @return void
 */
function decodeBase64(s) {
    var e={},i,b=0,c,x,l=0,a,r='',w=String.fromCharCode,L=s.length;
    var A="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    for(i=0;i<64;i++){e[A.charAt(i)]=i;}
    for(x=0;x<L;x++){
        c=e[s.charAt(x)];b=(b<<6)+c;l+=6;
        while(l>=8){((a=(b>>>(l-=8))&0xff)||(x<(L-2)))&&(r+=w(a));}
    }
    return r;
};

Object.size = function(obj) {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
};


	
	function loader_content(type){
		if(typeof(type) == 'undefined'){
			type = 'pulsing-bar';
		}
		var loaders = {
			'pulsing-bar' : '<div class = "pulsing-bar loading-animation">\
			  <div class="rect1"></div>\
			  <div class="rect2"></div>\
			  <div class="rect3"></div>\
			  <div class="rect4"></div>\
			</div>',
			'spinning-box' : '<div class = "spinning-box loading-animation">\
			</div>', 
			'strings' : '			<div class="loader loading-animation" style="display:block;">\
				<div class="load-content">\
					<div class="string1">\
						<div class="rect1"></div>\
						<div class="rect2"></div>\
						<div class="rect3"></div>\
						<div class="rect4"></div>\
						<div class="rect5"></div>\
					</div><!-- .string1 -->\
					<div class="string2">\
						<div class="rect1"></div>\
						<div class="rect2"></div>\
						<div class="rect3"></div>\
						<div class="rect4"></div>\
						<div class="rect5"></div>\
					</div><!-- .string2 -->\
					<div class="string3">\
						<div class="rect1"></div>\
						<div class="rect2"></div>\
						<div class="rect3"></div>\
						<div class="rect4"></div>\
						<div class="rect5"></div>\
					</div><!-- .string3 -->\
					<div class="string4">\
						<div class="rect1"></div>\
						<div class="rect2"></div>\
						<div class="rect3"></div>\
						<div class="rect4"></div>\
						<div class="rect5"></div>\
					</div><!-- .string4 -->\
					<div class="string5">\
						<div class="rect1"></div>\
						<div class="rect2"></div>\
						<div class="rect3"></div>\
						<div class="rect4"></div>\
						<div class="rect5"></div>\
					</div><!-- .string5 -->\
					<div class="string6">\
						<div class="rect1"></div>\
						<div class="rect2"></div>\
						<div class="rect3"></div>\
						<div class="rect4"></div>\
						<div class="rect5"></div>\
					</div><!-- .string6 -->\
				</div><!-- .load-content -->\
			</div><!-- .loader -->',
			'stage' : '<div class="stage loading-animation">\
				<div class="stage-dot"></div><!-- .stage-dot -->\
				<div class="stage-pin"></div><!-- .stage-pin -->\
			</div><!-- .stage -->'
			
			
		}
		return loaders[type]
	}
	
	
	
	//Cross Browser solution for placeholders
	$(function() {
		$('[placeholder]').focus(function() {
			var input = $(this);
			if (input.val() == input.attr('placeholder')) {
				input.val('');
				input.removeClass('placeholder');
			}
		}).blur(function() {
			var input = $(this);
			if (input.val() == '' || input.val() == input.attr('placeholder')) {
				input.addClass('placeholder');
				input.val(input.attr('placeholder'));
			}
		}).blur();
		$('[placeholder]').parents('form').submit(function() {
			$(this).find('[placeholder]').each(function() {
				var input = $(this);
				if (input.val() == input.attr('placeholder')) {
					input.val('');
				}
			})
		});
	});

    //IE alert toggle
	function unhide(iewarn) {
    var item = document.getElementById(iewarn);
    if (item) {
      item.className=(item.className=='hidden')?'unhidden':'hidden';
    }
	}


	/**
	 * mx_alert function.
	 * 
	 * @access public
	 * @param string message
	 * @param object options
	 * @return void
	 */
	function mx_alert( message, options ) {
		
		var alert = $('#activity-alert');

		if( alert.length ) {
			
			alert.find('.message').text(message);
			
			//set the alert color/type
			if( typeof options.type != 'undefined' ) {
				var curClass = alert.attr('class').match(/alert-\S+/g);
				
				if( curClass ) {
					//console.log(curClass[0]);
					alert.removeClass( curClass[0] );
				}
				
				alert.addClass('alert-'+options.type);
			} else {
				alert.addClass('alert-info');
			}
			
			// add a button
			var  body = '<a href="'+options.link_url+'" class="btn btn-default btn-xs ">'+options.link_text+'</a>';
			
			alert.find('.options').html(body);
			
			alert.removeClass('hide');
			
			setTimeout(function() {
				alert.removeClass('out').addClass('in');
			}, 10)
			
			setTimeout(function() {
				alert.removeClass('in').addClass('out');
				
				alert.addClass('hide');
			}, 9000)
			
			
		}
		
		$(document).on('click', '#activity-alert.dismiss', function(e) {
			e.preventDefault(); 
			
			$('#activity-alert').removeClass('in').addClass('out');
			
			setTimeout(function() {
				$('#activity-alert').removeClass('out').addClass('in');
			}, 500)

			
		});
	}
	
	



	
	
	
(function () {
  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();



// disable scroll on nubmers
document.addEventListener("mousewheel", function(event){
  if(document.activeElement.type === "number"){
      document.activeElement.blur();
  }
}, {'passive' : true });



if( typeof( 'getHashParams') !== 'function') {
	function getHashParams() {

    var hashParams = {};
    var e,
        a = /\+/g,  // Regex for replacing addition symbol with a space
        r = /([^&;=]+)=?([^&;]*)/g,
        d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
        q = window.location.hash.substring(1);

    while (e = r.exec(q))
       hashParams[d(e[1])] = d(e[2]);

    return hashParams;
	}
}

if( typeof( 'mx_scrollTo') !== 'function') {
	function mx_scrollTo( $anchor , $offset){
		var $offset = $offset || 0;
	  $('html,body').animate({
	    scrollTop: $($anchor).offset().top + $offset
	  }, 1000);
	}
}



function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

